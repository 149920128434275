import {onDomReady} from "../../components/dynamic/observer";
import Swiper, {Pagination} from "swiper";

onDomReady(() => {
    document.querySelectorAll('[data-product-top-slider]')
        .forEach((container) => {
            const primaryEl = container.querySelector('[data-product-top-slider-primary]');
            const paginationEl = container.querySelector('[data-product-top-slider-pagination]');

            let primarySlider, secondarySlider;

            if (primaryEl) {
                primarySlider = new Swiper(primaryEl, {
                    modules: [Pagination],
                    preventInteractionOnTransition: true,
                    pagination: {
                        el: paginationEl,
                    },
                });
            }

            const secondaryEl = container.querySelector('[data-product-top-slider-secondary]');
            if (secondaryEl) {
                secondarySlider = new Swiper(secondaryEl, {
                    preventInteractionOnTransition: true,
                    slidesPerView: "auto",
                    clickable: true,
                    freeMode: true,
                    breakpoints: {
                        768: {
                            spaceBetween: 6
                        },
                        1200: {
                            direction: "horizontal",
                            spaceBetween: 8
                        }
                    }
                });
            }

            if (primarySlider && secondarySlider) {
                primarySlider.on('activeIndexChange', () => {
                    removeActiveFromSlides();

                    secondarySlider.slideTo(primarySlider.activeIndex);
                    secondarySlider.slides[primarySlider.activeIndex].classList.add('_active');
                });

                secondarySlider.slides.forEach((slide, index) => {
                    slide.addEventListener('click', () => {
                        removeActiveFromSlides();

                        primarySlider.slideTo(index);
                        slide.classList.add('_active');
                    });
                });
            }

            const removeActiveFromSlides = () => {
                secondarySlider.slides.map((slide) => slide.classList.remove('_active'));
            }
    });
});