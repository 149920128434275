require('../scss/app.scss');
require('./svg/svg.js');

require('../fonts/Roboto_Flex/stylesheet.css');
require('../fonts/Manrope/stylesheet.css');


window.noZensmooth = true;

require('../vue/app');
require('@vueform/slider/themes/default.css')

require('./sliders/sliders.js');
require('./header/header');
require('./common/ajax-redirect');
require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./offices/index');
require('fslightbox');
require('./product/product');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./filters/project-filters');

require('./field/phone.js');
require('./field/floating.js');

require('./cart/cart');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');