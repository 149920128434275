<template>
  <div class="cart-order-block cart-order-block_contacts">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          03
        </div>
      </div>
      <h4 class="cart-order-block__title">
        способ оплаты
      </h4>
    </div>


    <div class="cart-order-block__content form-field">
      <ItemErrors :errors="errors.payment"/>
      <ul class="cart-order-block__radio-list _pay">
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="order.payment" id="order_cash" value="10">
            <label for="order_cash" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                НАЛИЧНЫМИ
              </span>
              <span class="cart-order-block__label-subtitle">
                Оплата наличными при получении заказа
              </span>
              <span class="cart-order-block__label-icon"></span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" checked v-model.number="order.payment" id="order_transfer" value="20">
            <label for="order_transfer" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                БАНКОВСКОЙ КАРТОЙ
              </span>
              <span class="cart-order-block__label-subtitle">
                Оплата банковской картой при получении заказа
              </span>
              <span class="cart-order-block__label-icon"></span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors";
import {mapWritableState} from "pinia";
import {useOrderFormStore} from "../store/modules/ordereForm";

export default {
  name: "TheCartOrderPayment",
  components: {
    ItemErrors
  },
  computed: {
    ...mapWritableState(useOrderFormStore, [
        'order',
        'errors'
    ]),
  },
}
</script>

<style lang="scss">

</style>