<template>
  <div class="order-form">
    <div class="order-form__form">
      <TheCartOrderContacts/>
      <TheCartOrderRecieve/>
      <TheCartOrderPayment/>
      <TheCartOrderComment/>

      <button class="order-form__button button" @click="sendOrder">
        Оформить заказ
      </button>
    </div>
    <div class="order-form__side">
      <OrderSide/>
    </div>
  </div>

</template>

<script>
import TheCartOrderContacts from "./TheCartOrderContacts.vue";
import TheCartOrderRecieve from "./TheCartOrderRecieve.vue";
import TheCartOrderComment from "./TheCartOrderComment.vue";
import TheCartOrderPayment from "./TheCartOrderPayment.vue";
import OrderSide from "./OrderSide.vue";
import {mapActions} from "pinia";
import {useOrderFormStore} from "../store/modules/ordereForm";

export default {
  name: "OrderForm",
  components: {
    OrderSide,
    TheCartOrderPayment,
    TheCartOrderComment,
    TheCartOrderRecieve,
    TheCartOrderContacts
  },
  methods: {
    ...mapActions( useOrderFormStore , ["send"]),
    async sendOrder() {
      const success = await this.send(false);
    }
  }
}
</script>

<style scoped>

</style>