import { defineStore } from 'pinia';
import OrderService from "../../services/order.service";
import { mande } from 'mande'

const checkoutApi = mande('/order/api/checkout')

export const useOrderFormStore = defineStore(
    'orderForm',
    {
        state: () => {
            return {
                order: {
                    phone: '',
                    name: '',
                    receiving: '20',
                    address: '',
                    payment: '20',
                    comment: '',
                },
                errors: {
                    receiving: '',
                    phone: '',
                    name: ''
                },
                redirectBaseUrl: '/cart/success',
                checkout: ''
            }
        },
        getters: {

        },
        actions: {
            async send() {
                try {
                    const result = await OrderService.save(this.order);
                    if (result.data.errors) {
                        this.errors = result.data.errors;
                    } else {
                        this.redirectToSuccessPage(result);
                    }
                    return true;
                } catch (error) {
                    console.log('Произошла ошибка')
                }
                return false;
            },
            redirectToSuccessPage(result) {
                window.location.pathname = `${this.redirectBaseUrl}/${result.data.order_id}`;
            },
            async getCheckout() {
                try {
                    this.checkout = await checkoutApi.post()
                } catch (error) {
                    console.log('error')
                }
            },
        }
    }
);
