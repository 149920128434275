import {onDomReady} from "../../components/dynamic/observer";

class Product{
    constructor() {
        this.quantity = document.querySelector('[data-product-quantity]');
        this.inc = document.querySelector('[data-product-inc]');
        this.dec = document.querySelector('[data-product-dec]');
        this.totals = document.querySelectorAll('[data-product-total]');
        this.singlePrice = document.querySelector('[data-single-price]').dataset.singlePrice
        this.toCartButtons = document.querySelectorAll('[data-to-cart]');
        this.successQuantity = document.querySelector('[data-success-quantity]')
    }

    bindClicks() {
        this.inc.addEventListener('click', (e) => {
            let quantity = parseInt(this.quantity.innerHTML)
            this.quantity.innerHTML = (quantity + 1).toString();
            this.successQuantity.innerHTML = this.quantity.innerHTML
            this.setTotals();
        })

        this.dec.addEventListener('click', (e) => {
            let quantity = parseInt(this.quantity.innerHTML)
            if (quantity > 1) {
                this.quantity.innerHTML = (quantity - 1).toString();
                this.successQuantity.innerHTML = this.quantity.innerHTML
                this.setTotals();
            }
        })
    }

    setTotals() {
        this.totals.forEach((total) => {
            let newTotal = parseInt(this.singlePrice) * parseInt(this.quantity.innerHTML);
            newTotal = this.formatPrice(newTotal);
            total.innerHTML = newTotal;
            this.toCartButtons.forEach((button) => {
                button.dataset.quantity = this.quantity.innerHTML;
            })
        })
    }

    formatPrice(value) {
        return new Intl.NumberFormat("ru").format(value) + ' ₽';
    }
}

onDomReady(() => {
    if (document.querySelector('[data-product-page]')) {
        const product = new Product();
        product.bindClicks();
    }
});