<template>
  <div class="cart-order-block cart-order-block_comment">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          04
        </div>
      </div>
      <h4 class="cart-order-block__title">
        Комментарий к заказу
      </h4>
    </div>


    <div class="cart-order-block__content form-field _field-text">
      <label for="order_comment" class="cart-order-block__label">
        Оставьте ваши вопросы, пожелания к заказу
      </label>
      <textarea
          name="comment"
          id="order_comment"
          cols="30"
          rows="10"
          class="cart-order-block__comment text text__small"
          placeholder="Комментарий"
          v-model="order.comment"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useOrderFormStore} from "../store/modules/ordereForm";

export default {
  name: "TheCartOrderComment",
  computed: {
    ...mapWritableState(useOrderFormStore, [
      'order',
    ]),
  },
}
</script>

<style lang="scss">

</style>