<template>
    <div :class="blockClasses">
        <label v-if="field.label" :class="elementClasses('label')">{{ field.label }}</label>
        <div :class="elementClasses('wrapper')" v-if="valueItem.value">
            <div :class="elementClasses('values')">
                <div :class="elementClasses('value')" v-for="(choice, index) in field.choices">
                    <input type="checkbox" :id="id(choice, index)" :value="choice.key" v-model="valueItem.value" :disabled="choice.disabled || field.disabled">
                    <label :for="id(choice, index)" :class="elementClasses('label')">
                        {{ choice.label }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";

export default {
  name: "CheckboxList",
  mixins: [
    fieldMixin
  ],
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = [];
      }
    },
    id(choice, index) {
      index = index | 0;
      return `${this.field.key}_${choice.key}_${index}`;
    }
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>