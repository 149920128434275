import { createApp } from 'vue';
import { createPinia } from "pinia";
import { initFilters } from "./filter/init";

import App from './App.vue';

import OrderForm from "./components/OrderForm.vue";

import mitt from 'mitt';
import maska from "maska";

const pinia = createPinia();
initFilters(pinia);

const emitter = mitt();


if (document.querySelector('#app')) {
    const app = createApp(App);
    app.mount('#app');
}

if (document.querySelector('#order-form')) {
    const app = createApp(OrderForm);
    app.use(pinia, maska);
    app.mount('#order-form');
}