<template>
  <div class="cart-order-block cart-order-block_contacts">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          02
        </div>
      </div>
      <h4 class="cart-order-block__title">
        способ получения
      </h4>
    </div>


    <div class="cart-order-block__content form-field">
      <div class="cart-order-block__radio-label">
        Выберите способ получения товара
      </div>
      <ul class="cart-order-block__radio-list _receiving">
<!--        <li class="cart-order-block__radio-item">-->
<!--          <div class="cart-order-block__radio">-->
<!--            <input type="radio" name="receive" checked v-model.number="order.receiving" id="delivery" value="10" @click="setClean">-->
<!--            <label for="delivery" class="cart-order-block__input-label">-->
<!--              <span class="cart-order-block__radio-icon cart-order-block__radio-icon_delivery">-->
<!--                <DeliveryIcon/>-->
<!--              </span>-->
<!--              <span class="cart-order-block__label-title  text text__main text__main_medium">-->
<!--                Доставка-->
<!--              </span>-->
<!--            </label>-->
<!--          </div>-->
<!--        </li>-->
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="receive" v-model.number="order.receiving" id="self" value="20" @click="setDefault">
            <label for="self" class="cart-order-block__input-label">
              <span class="cart-order-block__radio-icon cart-order-block__radio-icon_self">
                  <SelfDeliveryIcon/>
              </span>
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Самовывоз
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div class="cart-order-block__content cart-order-block__address form-field _field-text" v-if="order.receiving === '10'"
    >
      <label for="order_address" class="cart-order-block__label">
        Адрес
      </label>
      <textarea
          name="comment"
          id="order_address"
          cols="30"
          rows="10"
          class="cart-order-block__comment text text__small"
          placeholder="Укажите адрес доставки"
          v-model="order.address"
      >
      </textarea>
    </div>
  </div>
  <ItemErrors :errors="errors.receiving"/>
</template>

<script>
import ItemErrors from "./ItemErrors";
import {mapWritableState} from "pinia";
import {useOrderFormStore} from "../store/modules/ordereForm";
import SelfDeliveryIcon from "./SelfDeliveryIcon.vue";
import DeliveryIcon from "./DeliveryIcon.vue";

export default {
  name: "TheCartOrderRecieve",
  components: {
    SelfDeliveryIcon,
    DeliveryIcon,
    ItemErrors
  },
  computed: {
    ...mapWritableState(useOrderFormStore, [
      'order',
      'errors'
    ]),
  },
  methods: {
    setDefault() {
      this.order.address = 'Комсомольская улица, 13, Ленинский район, Киров'
    },
    setClean() {
      this.order.address = ''
    },
  }
}
</script>

<style lang="scss">

</style>