<template>
  <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M27.4817 5.62383L17.4817 0.123829C17.334 0.0425953 17.1682 0 16.9997 0C16.8312 0 16.6654 0.0425953 16.5177 0.123829L6.51769 5.62383C6.3609 5.71015 6.23016 5.83698 6.1391 5.99106C6.04804 6.14515 6 6.32085 6 6.49983C6 6.67881 6.04804 6.85451 6.1391 7.0086C6.23016 7.16268 6.3609 7.2895 6.51769 7.37583L15.9997 12.5908V23.3098L12.9637 21.6398L11.9997 23.3908L16.5177 25.8758C16.6653 25.9572 16.8311 25.9999 16.9997 25.9999C17.1683 25.9999 17.3341 25.9572 17.4817 25.8758L27.4817 20.3758C27.6385 20.2895 27.7693 20.1627 27.8605 20.0087C27.9516 19.8546 27.9997 19.6788 27.9997 19.4998V6.49983C27.9997 6.32082 27.9516 6.14509 27.8605 5.99101C27.7693 5.83692 27.6385 5.71011 27.4817 5.62383ZM16.9997 2.14183L24.9247 6.49983L16.9997 10.8578L9.07469 6.49983L16.9997 2.14183ZM25.9997 18.9088L17.9997 23.3088V12.5898L25.9997 8.18983V18.9088Z" fill="#000000"/>
    <path id="Vector_2" d="M8 13H0V11H8V13ZM10 21H2V19H10V21ZM12 17H4V15H12V17Z" fill="#000000"/>
  </svg>
</template>

<script>
export default {
  name: "DeliveryIcon"
}
</script>