<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26" fill="none">
    <path d="M6.75 21.6875V19.1C6.75 18.9412 6.87872 18.8125 7.0375 18.8125H20.8375C20.9963 18.8125 21.125 18.9412 21.125 19.1V21.6875C21.125 22.4814 21.7686 23.125 22.5625 23.125H25.4375C26.2314 23.125 26.875 22.4814 26.875 21.6875V9.26713C26.875 9.21503 26.8608 9.1639 26.834 9.11922L22.6044 2.06979C22.5784 2.02649 22.5316 2 22.4811 2H5.39389C5.3434 2 5.2966 2.02649 5.27063 2.06979L1.04097 9.11922C1.01416 9.1639 1 9.21503 1 9.26713V21.6875C1 22.4814 1.64359 23.125 2.4375 23.125H5.3125C6.10641 23.125 6.75 22.4814 6.75 21.6875Z" stroke="black" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "SelfDeliveryIcon"
}
</script>