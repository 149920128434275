<template>
  <div class="order-side">
    <div class="order-side__head">
      <div class="order-side__head-title">Итого</div>
      <div class="order-side__head-value">{{ checkout.totalWithDelivery }} ₽</div>
    </div>

    <div class="order-side__head-add" v-if="order.receiving === '10'">
      <div class="order-side__head-add-block">
        <div class="order-side__head-add-label">
          Сумма заказа
        </div>
        <div class="order-side__head-add-value">
          {{ checkout.totalByProducts }} ₽
        </div>
      </div>
      <div class="order-side__head-add-block">
        <div class="order-side__head-add-label">
          Доставка
        </div>
        <div class="order-side__head-add-value">
          {{ checkout.deliveryCost }}
        </div>
      </div>
    </div>

    <div class="order-side__products">
      <div class="order-side__products-title">
        Количечество товаров в заказе:  {{ checkout.totalCount }}
      </div>
      <ul class="order-side__products-list">
        <li class="order-side__product" v-for="item in checkout.products">
        <span class="order-side__product-title">
            {{ item.productName }}
        </span>
          <span class="order-side__product-value">
            {{ item.quantity}} x {{ item.price }} ₽ / 1 кг
        </span>
        </li>
      </ul>
    </div>

    <button class="order-side__button button" @click="sendOrder">
        Оформить заказ
    </button>
  </div>
</template>

<script>
import {mapActions, mapWritableState} from "pinia";
import {useOrderFormStore} from "../store/modules/ordereForm";

export default {
  name: "OrderSide",
  computed: {
    ...mapWritableState(useOrderFormStore, [
      'checkout',
      'order'
    ]),
  },
  methods: {
    ...mapActions( useOrderFormStore , ["getCheckout", "send"]),
    async setCheckout() {
      const success = await this.getCheckout(false);
    },
    async sendOrder() {
      const success = await this.send(false);
    }
  },
  beforeMount() {
    this.setCheckout()
  }
}
</script>

<style lang="scss">

</style>