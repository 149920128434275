<template>
  <div class="cart-order-block cart-order-block_contacts">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          01
        </div>
      </div>
      <h4 class="cart-order-block__title">
        Контактная информация
      </h4>
    </div>


    <div class="cart-order-block__content form-field">
      <ul class="cart-order-block__fields">
        <li class="cart-order-block__field _field-text">
          <label for="order_name" class="cart-order-block__label">
            Получатель
          </label>
          <input type="text"
                 id="order_name"
                 class="cart-order-block__input"
                 v-model="order.name"
          >
          <ItemErrors :errors="errors.name"/>
        </li>
        <li class="cart-order-block__field _field-text">
          <label for="order_phone" class="cart-order-block__label">
            Номер телефона
          </label>

          <input
              v-maska="'+7 (###) ###-##-##'"
              type="text"
              v-model="order.phone"
              id="order_phone"
              name="phone"
              required="required"
              class="cart-contacts__input required"
          >

          <ItemErrors :errors="errors.phone"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors";
import {useOrderFormStore} from "../store/modules/ordereForm";
import {mapWritableState} from "pinia";
import { maska } from 'maska'

export default {
  name: "TheCartOrderContacts",
  components: {
    ItemErrors
  },
  computed: {
    ...mapWritableState(useOrderFormStore, [
      'order',
      'errors'
    ]),
  },
  directives: {
    maska
  },
  methods: {
    validatePhone(value) {
      const validRegex = /^.{18}$/;
      if (!value.match(validRegex)) {
        this.errors.phone = 'Некорректный телефон';
      } else {
        this.errors.phone = '';
      }
    },
  }
}
</script>

<style lang="scss">

</style>